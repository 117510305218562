import { ReactElement } from 'react';

import Icon, { IconProps } from '@components/atoms/Icon';

const LinkedIn = ({ size, ...rest }: IconProps): ReactElement => (
    <Icon size={size} viewBox="0 0 40.3 55.6" {...rest}>
        <g id="Group_10437" transform="translate(0)">
            <g id="Group_10436" transform="translate(0 0)">
                <path
                    id="Path_124"
                    d="M19.5,42.7c-0.4,0-0.7,0-1.1,0.1v7.1c0.3,0,0.6,0.1,0.8,0c2.2,0,3.6-1.2,3.6-3.8c0.2-1.7-1-3.2-2.7-3.4
			C19.9,42.6,19.7,42.6,19.5,42.7z"
                />
                <path
                    id="Path_125"
                    d="M10.5,42.7c-0.3,0-0.7,0-1,0.1V46C9.8,46,10.1,46,10.3,46c1.3,0,2.1-0.7,2.1-1.8
			C12.4,43.3,11.8,42.7,10.5,42.7z"
                />
                <path
                    id="Path_126"
                    d="M40.3,13.3c0-0.3-0.1-0.5-0.3-0.7L29.3,0.4c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0-0.1,0
			c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0H2.2C1,0,0,1,0,2.2v51.2c0,1.2,1,2.2,2.2,2.2h36c1.2,0,2.2-1,2.2-2.2
			v-40C40.3,13.4,40.3,13.4,40.3,13.3z M13.7,46.7c-0.9,0.8-2.1,1.2-3.3,1.1c-0.3,0-0.5,0-0.8,0v3.7H7.3V41.2c1-0.2,2.1-0.2,3.1-0.2
			c1.1-0.1,2.2,0.2,3.1,0.8c0.7,0.6,1.1,1.5,1.1,2.4C14.7,45.2,14.3,46,13.7,46.7z M23.5,50.2c-1.4,1-3.1,1.4-4.8,1.3
			c-0.9,0-1.8,0-2.6-0.2V41.2c1-0.2,2.1-0.2,3.1-0.2c1.5-0.1,3,0.3,4.2,1.1c1.2,0.9,1.8,2.4,1.7,3.9C25.3,47.6,24.7,49.1,23.5,50.2
			L23.5,50.2z M33.1,43h-4v2.4h3.7v1.9h-3.7v4.1h-2.3V41.1h6.3L33.1,43z M2.2,37V2.2h25.2v11.2c0,0.6,0.5,1.1,1.1,1.1h9.6V37H2.2z"
                />
                <path
                    id="Path_127"
                    d="M31.2,24.5c-0.1,0-1.6-0.2-3.9-0.2c-0.7,0-1.5,0-2.2,0c-3.6-2.9-7.1-6-10.3-9.4c0-0.2,0.1-0.4,0.1-0.5
			c0.3-3,0-5-0.9-6c-0.5-0.6-1.4-0.9-2.2-0.6c-0.9,0.3-1.5,0.9-1.8,1.8c-0.3,1.2,0.2,2.7,1.7,4.4c0,0,0.5,0.6,1.4,1.5
			c-0.6,2.9-2.1,9.3-2.8,12.4c-1.6,0.9-3.1,2.1-4.3,3.5l-0.1,0.1l0,0.1c-0.4,0.8-0.5,1.8-0.3,2.7c0.2,0.5,0.5,0.9,1,1l0.1,0
			c0.1,0,0.2,0,0.3,0c0.9,0,3.1-0.5,4.3-5.1l0.3-1.2c4.1-2.1,9.3-2.8,13.1-3c1.9,1.5,3.8,2.9,5.7,4.2l0.1,0c0.6,0.3,1.2,0.5,1.9,0.5
			l0,0c1.4,0,2.6-1.1,2.7-2.4l0-0.1c0.1-0.8-0.1-1.7-0.6-2.3C33.5,25.1,32.4,24.6,31.2,24.5z M7,33.8C7,33.8,7,33.7,7,33.8
			c-0.1-0.4,0-0.8,0.2-1.2c0.7-0.8,1.5-1.6,2.4-2.2C8.7,33.5,7.3,33.8,7,33.8z M12.8,13L12.8,13c-1.4-1.7-1.4-2.5-1.3-2.8
			c0.1-0.3,0.4-0.5,0.7-0.6c0.3-0.1,0.5-0.1,0.6,0.1c0.3,0.4,0.6,1.6,0.5,3.8C12.9,13.2,12.8,13,12.8,13z M12,27L12,27L12,27
			c0.7-3,1.7-7.3,2.3-10l0,0v0c1.9,1.9,4.8,4.6,8.2,7.5h0l0.1,0C19,24.8,15.4,25.7,12,27z M33.4,28c-0.2,1-0.7,1.1-1.2,1.1l0,0
			c-0.4,0-0.8-0.1-1.1-0.3c-1.3-0.8-2.6-1.8-3.9-2.7h0c2.2,0,3.7,0.1,3.8,0.1c0.8,0,1.5,0.3,2.1,0.8C33.3,27.3,33.4,27.7,33.4,28z"
                />
            </g>
        </g>
    </Icon>
);

export default LinkedIn;
